import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class ManageInterestService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    getInterests(data: any) {
        return this.http.post(this.url + 'interest/admin/list', data);
    }

    createInterests(data: any) {
        return this.http.post(this.url + 'interest/admin/add', data);
    }

    updateInterest(data: any) {
        return this.http.patch(this.url + 'interest/admin/edit', data);
    }

    deleteInterest(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'category/delete', httpOptions);
    }

    importInterest(data: any) {
        return this.http.post(this.url + 'import/areaOfInterest', data);
    }


    uploadImage(data:any){
        return this.http.post(this.url + 'user/images', data);
    }


    createService(data:any){
        return this.http.post(this.url + 'service/create', data);
    }

    getServices(data:any){
        return this.http.post(this.url + 'service/list', data);
    }

    deleteService(data:any){
        return this.http.post(this.url + 'interest/admin/delete', data);
    }

    editService(data:any){
        return this.http.post(this.url + 'interest/admin/edit', data);
    }

    createInterest(data:any){
        return this.http.post(this.url + "interest/admin/add",data);
    }


    getInterestList(data:any){
        return this.http.post(this.url + 'interest/admin/list', data);
    }

    getInterestBySearch(data:any){
        return this.http.post(this.url + 'interest/admin/get', data);
    }

    deleteManageInterest(data:any){
        return this.http.post(this.url + "interest/admin/delete", data)
    }

}