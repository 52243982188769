export const environment = {
  production: false,

   API_URL: "https://api.wareeg.com/v1/",
  //  API_URL: "https://beta-api.wareeg.io/v1/",
  
SERVER: "prod",
// SERVER: "preprod",

};
