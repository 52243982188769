

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as _  from 'lodash';


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()

export class ManageReferalService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    public exportAsExcelFile(json: any[], excelFileName: string): void {
        console.log(json ,"xl")
            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
            console.log('worksheet',worksheet);
            const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            console.log(workbook,"wb")
            const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            console.log(workbook,"excelbuffer")
    
            
            this.saveAsExcelFile(excelBuffer, excelFileName);
          }
        
          private saveAsExcelFile(buffer: any, fileName: string): void {
            const data: Blob = new Blob([buffer], {
              type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
          }

    getNotificationList(data: any) {
        return this.http.post(this.url + 'notification/getNotifications', data);
    }

    createNotification(data: any) {
        return this.http.post(this.url + 'notification/saveNotification', data);
    }

    deleteNotification(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'notification/deleteNotification', httpOptions);
    }

    updateNotification(data: any) {
        return this.http.post(this.url + "notification/updateNotification", data);
    }


    getCaregiverList(data: any) {
        return this.http.post(this.url + "user/list-caregiver", data);
    }

    approveCaregiver(data: any) {
        return this.http.post(this.url + "user/approve-caregiver", data);
    }

    getCaregiverById(data: any) {
        return this.http.post(this.url + "user/view-caregiver", data);
    }

    setStatus(data: any) {
        return this.http.post(this.url + "user/change-status", data);
    }

    getManageLocalList(page,pageSize) {
        const url = `${this.url}referral/getRequestedUsers?page=${page}&pageSize=${pageSize}`;
        return this.http.get(url);
    }

    geRefferedHistoryList(page,pageSize) {
        const url = `${this.url}referral/getReferralHistory?page=${page}&pageSize=${pageSize}`;
        return this.http.get(url);
    }

    getServices(page,pageLimit, searchKey){
        const url = `${this.url}admins/list-service?page=${page}&pageLimit=${pageLimit}`;
          return this.http.get(url);
    }

    getUserStatus(data: any) {
        return this.http.post(this.url + "referral/update", data);
    }

    getManageLocalById(data: any) {
        return this.http.post(this.url + 'user/get', data)
    }

}